.App {
  padding: 0;
}

.Header {
  border-bottom: 1px solid #ddd;
}

.Sidebar {
  width: 120px;
  margin: 5px;
  position: sticky;
  top: 62px;
  align-self: flex-start;
  align-items: start;
}

.Sidebar .nav-item {
  width: 100%;
}

.Sidebar .nav-link {
  padding: 4px 4px 4px 16px;
}

.Sidebar a {
  color: #444;
}

.Sidebar a:hover {
  background-color: #eee;
}

.Sidebar a:visited {
  color: #444;
}

.Sidebar .nav-item .active {
  background-color: #def;
}

.Content {
  margin-top: 10px;
}

.Post {
  align-items: start;
  padding-top: 5px;
  border-bottom: 1px solid #eee;
}

.Post:hover {
  background-color: #f8f8f8;
}

.Post a {
  color: #14c;
  text-decoration: none;
}

.Post a:visited {
  color: #14c;
}

.More {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.InputField {
  margin-top: 15px;
  margin-bottom: 15px;
}

.Sidebar a,
.dropdown-item,
.dropdown-item.active {
  color: #444;
}

.Sidebar .nav-item .active,
.dropdown-item.active {
  background-color: #def;
}

.Write {
  margin-bottom: 10px;
  padding: 30px 0 40px 0;
  border-bottom: 1px solid #eee;
}

.Write form {
  width: 100%;
}
